export default {
  methods: {
    afterSubmit(message, routeName, params = {}, is_route_real_state = false) {

      const message_body = is_route_real_state ? this.$t('general.success_please_wait') : message

      const timeout = is_route_real_state ? 5000 : 1000

      this.$bvToast.toast(message_body, {
        title: this.$t('general.success'),
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      })

      setTimeout(() => this.$router.push({
        name: routeName,
        params,
      }), timeout)
    },
    moduleFilter(searchFields, $val) {
      let $filters = ''
      const $fields = []
      Object.keys(searchFields)
        .forEach(x => {
          if ($fields.indexOf(x) < 0) {
            $fields.push(x)
          }
        })
      $fields.forEach(x => {
        if (x === 'roles') {
          $filters += $val[x] ? `&${x}=[${$val[x]}]` : ''
        } else if (x !== 'roles') {
          $filters += $val[x] ? `&${x}=${$val[x]}` : ''
        }
      })
      return $filters
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },

    getSlug(title) {
      let slug = ''
      // Change to lower case
      const titleLower = title.toLowerCase()

      // Trim the last whitespace
      slug = titleLower.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')

      // regex to remove symbols
      slug = slug.replace(/[!@#\$%\^\&*\)\(+=._?<>]/g, '')

      return slug
    },
  },
}
