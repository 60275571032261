import { render, staticRenderFns } from "./tableIndex.vue?vue&type=template&id=5d257372&scoped=true"
import script from "./tableIndex.vue?vue&type=script&lang=js"
export * from "./tableIndex.vue?vue&type=script&lang=js"
import style0 from "./tableIndex.vue?vue&type=style&index=0&id=5d257372&prod&lang=scss"
import style1 from "./tableIndex.vue?vue&type=style&index=1&id=5d257372&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d257372",
  null
  
)

export default component.exports